(function () {
  "use strict";
  angular
    .module("app")
    // .run(actionCable)
    .constant("BASE_URL", getEnvironmentURL("BASE_URL"))
    .constant("DOMAIN_URL", getEnvironmentURL("DOMAIN_URL"))
    .constant("PDFGENERATOR", getEnvironmentURL("PDFGENERATOR"))
    .constant("FORMS_URL", getEnvironmentURL("FORMS_URL"))
    .run(run);

  function getEnvironmentURL(constantName) {
    const hostname = window.location.hostname;

    // Development URLs
    const devURLs = {
      BASE_URL: "http://localhost:3000",
      DOMAIN_URL: "http://localhost:3001",
      PDFGENERATOR: "http://localhost:4000/pdf_generator",
      FORMS_URL: "http://lvh.me:3003",
    };

    // Production URLs
    const prodURLs = {
      BASE_URL: "https://crm.cruzinsurancegroup.com",
      DOMAIN_URL: "https://crm.cruzinsurancegroup.com",
      PDFGENERATOR: "https://crm.cruzinsurancegroup.com/pdf_generator",
      FORMS_URL: "https://crmforms.cruzinsurancegroup.com",
    };

    // Determine environment and return the appropriate URL
    if (hostname.includes("localhost") || hostname.includes("lvh.me")) {
      return devURLs[constantName];
    } else if (hostname === "crm.cruzinsurancegroup.com") {
      return prodURLs[constantName];
    }

    // Default to production URLs if hostname doesn't match any criteria
    return prodURLs[constantName];
  }

  run.$inject = [
    "$rootScope",
    "$state",
    "authService",
    "$document",
    "$transitions",
    "user",
    "$window",
    "globalService",
  ];
  function run(
    $rootScope,
    $state,
    $authService,
    $document,
    $transitions,
    $user,
    $window,
    $globalService
  ) {
    var user = $user.getCurrentUser();

    if (user && user.force_password_reset) {
      $state.go("newPassword");
    }
    $transitions.onSuccess({}, function () {
      $document[0].body.scrollTop = $document[0].documentElement.scrollTop = 0;
    });

    $transitions.onError({}, function (trans) {
      switch (trans.error().detail) {
        case "ALREADY_LOGGED_IN":
          $state.go("home");
          break;
        case "UNAUTHORIZED":
          //console.log('UNAUTHORIZED')
          unauthorizedHandler();
          break;
        default:
          $state.go("404");
      }
    });

    $rootScope.$on("UNAUTHORIZED", unauthorizedHandler);

    function unauthorizedHandler() {
      if ($authService.logout()) {
        $state.go("login");
      }
    }

    $globalService.getSystemVersions().then(function (res) {
      if ($window.localStorage.hasOwnProperty("system_version")) {
        var currentVersion = angular.fromJson(
          $window.localStorage.getItem("system_version")
        );
        if (currentVersion.version != res.version) {
          $window.localStorage.setItem("system_version", angular.toJson(res));
          $window.location.reload(true);
        }
        var user = $user.getCurrentUser();
        if (user) {
          $globalService.refreshPermissions().then(function (data) {
            $user.setCurrentUser(data.user);
            $rootScope.$emit("updateProfile");
          });
        }
      } else {
        $window.localStorage.setItem("system_version", angular.toJson(res));
        $window.location.reload(true);
      }
    });

    window.addEventListener("offline", function (event) {
      $rootScope.$emit("offline", {});
    });

    window.addEventListener("online", function (event) {
      $rootScope.$emit("online", {});
    });
  }
})();
