(function(){
  'use strict';
  angular
  .module('app')
  .component('webHeader', {
    templateUrl: 'app/components/web-header/web-header.html',
    controller: WebHeaderController
  });

  WebHeaderController.$inject = ['user','authService','$state','$rootScope','globalService','$sce'];

  function WebHeaderController($user, $authService, $state, $rootScope, $globalService, $sce) {
    var vm = this;
    vm.doLogout = doLogout;
    vm.bread_crum = {};
    vm.search = search;
    

    vm.$onInit = function(){
      findNotifications()
      vm.currentUser = $user.getCurrentUser();
      vm.filters = [
        {key: 'CRMID', value: 'CRMID'},
        {key: 'member_id', value: 'Member ID'},
        {key: 'secondary_members_ids', value: 'Secondary Members'},
        {key: 'phone', value: 'Phone'},
        {key: 'full_name', value: 'Name or Lastname'},
        {key: 'history', value: 'History Members ID'},
        {key: 'application_id', value: 'Application ID'},
        {key: 'email', value: 'Email'}
      ];
      vm.params = {
        filter_type: vm.filters[0]
      }
      
    }

    function findNotifications(){
      $globalService.notifications.query().$promise
      .then(function (res) {
        vm.notifications = _.forEach(res.data, function(data){
          data.description = $sce.trustAsHtml(data.description);
          return data;
        });
      })
    }

    vm.updateNotification = function(notification){
      $globalService.notifications.update({id: notification.id}, {notification: {read: true}}).$promise
      .then(function(res){
        notification.read = true;
        vm.notifications.splice(vm.notifications.indexOf(notification), 1);
      })
    }


    function doLogout() {
      if($authService.logout()){
        $state.go('login')
      }
    }

    function search(){
      if($state.current.name == "listApplications" || $state.current.name == 'dashboardAgent' || $state.current.name == 'dashboardManager'){
        searchApp();
      }
    }

    function searchApp(){
      $rootScope.$emit('searchApp',{params: vm.params})
    }

    $rootScope.$on('uploadBreadCrum', function(e,d){
      vm.current_page = $state.current.name;
      vm.bread_crum.current_page = d.current;
    })

    $rootScope.$on('newPersonalNotification', function(e,d){
      findNotifications();
    })
  }

})();
