(function(){
  'use strict';
  angular
    .module('app')
    .component('emailCampaing', {
      templateUrl: 'app/components/emailCampaing/emailCampaing.html',
      controller: EmailCampaingController
    });

    EmailCampaingController.$inject = ['user', '$rootScope', 'globalService', '$uibModal']

  function EmailCampaingController($user, $rootScope, $globalService, $uibModal) {
    var vm = this;
    vm.loadingRequests = false;
    vm.disableAdd = false;
    vm.currentUser = $user.getCurrentUser();
    vm.agenciesTypes = [{id: 0, text: 'Main Agency'},{id: 1, text: 'Sub Agency'}]
    vm.per = 20;
    vm.currentPage = 1;
    vm.filter = undefined;

    vm.$onInit = function(){
      vm.loading = true;
      $rootScope.$emit('uploadBreadCrum', {current: 'Email Campaing ', before: 'Home'});

      $globalService.emailCampaings.query({filter: vm.filter}).$promise
      .then(function(res){
        vm.data = res;
        vm.currentPage = vm.data.current_page;
        vm.loading = false;
        vm.loadingRequests = false;
      })
    }
    

    vm.showEmail = function(showEmail){
      vm.loading = true;
      $globalService.emailCampaings.get({id: showEmail.id}).$promise
      .then(function(res){
        vm.loading = false;
        var modal = $uibModal.open({
          animation: true,
          component: 'emailPanel',
          windowClass: 'show',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            email: function(){
              return res;
            }
          }
        });
  
        modal.result.then(function (res) {
          vm.$onInit();
        });
      })
    }

    vm.pageChanged = function () {
      vm.send = true;
      vm.loadingData = true;
      vm.currentPage = vm.data.current_page;
      $globalService.emailCampaings.query({
        page: vm.currentPage,
        per: vm.per,
        read: vm.read,
        order: vm.order,
        phone_number: vm.phone_number,
        date: vm.date
      }).$promise
      .then(function (res) {
        vm.send = false;
        vm.data = res;
        vm.loadingData = false;
      })
    };

    vm.sendMessage = function(){
      vm.sendingSms = true;
      if(!vm.currentUser.permits_granted.send_sms.active){
        swal('Error','You do not have permission to send SMS','info');
        return
      }

      $globalService.sendSms({ phone_number: vm.currentConversation.phone_number,
                               message: vm.message,
                               crmid: vm.currentConversation.crmid,
                               conversation_id: vm.currentConversation.id,
                               user_id: vm.currentConversation.user_id })
      .then(function(res){
        vm.sendingSms = false;
        vm.message = '';
        vm.messages.push(res);
        setTimeout(function(){
          var conversationBody = document.getElementsByClassName('conversation__body')[0]; 
          conversationBody.scrollTop = conversationBody.scrollHeight;
        },1)
      }, function(err){
        swal('Error',err.data.message,'error');
      })
    }

    vm.showSmsPanel = function(){
      var modal = $uibModal.open({
        animation: true,
        component: 'emailPanel',
        windowClass: 'show',
        backdrop: 'static',
        size: 'lg',
      });

      modal.result.then(function (res) {
        vm.$onInit();
      });
    }
  }

})();
