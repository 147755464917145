(function () {
  'use strict';
  angular
  .module('app')
  .component('smsPanel', {
    templateUrl: 'app/components/sms_notifications/sms_panel.html',
    controller: SmsPanelController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  SmsPanelController.$inject = ['$sce','globalService'];

  function SmsPanelController($sce, $globalService) {
    var vm = this;
    vm.params = {}
    vm.statuses = [
      {key: 0, value: "Enviada"},
      {key: 1, value: "En Proceso"},
      {key: 2, value: "Aprobada"},
      {key: 3, value: "Conflicto de Agentes"},
      {key: 4, value: "Activa"},
      {key: 5, value: "Baja"},
      {key: 6, value: "Pending Link"},
      {key: 7, value: "Pending Note"},
      {key: 8, value: "SSN Pending"},
    ]

    vm.$onInit = function() {
      vm.loading = true;
      $globalService.companies.query().$promise
      .then(function name(res) {
        vm.companies = res;  
      }, function(err) {
        vm.send = false;
      })
      $globalService.users.query().$promise
      .then(function(res){
        vm.agents = res;
        vm.loading = false;
      })
      
    }

    vm.save = function(sent){
      vm.loading = true;
      if(_.isEmpty(vm.params)){
        vm.loading = false;
        swal('Error','Should be complete the fields','error');
        return
      }
      if(!vm.params.message){
        vm.loading = false;
        swal('Error','Should be complete the message','error');
        return
      }
      if(sent){
        vm.params.send_sms = true;
      }
      if(vm.agentsSelected){
        console.log(_.pluck(vm.agentsSelected, 'id'))
        vm.params.user_id = _.pluck(vm.agentsSelected, 'id');
      }
      $globalService.sendMasiveSms(vm.params)
      .then(function(res){
        vm.loading = false;
        if(res.status == 'waiting'){
          swal('Info', res.message , 'info');
        }else{
          swal('Success',res.message, 'success');
          vm.params = {};
          vm.agentsSelected = undefined;
          vm.CompanySelected = undefined;
          vm.statusSelected = undefined;
        }
      })
    }

    

  

  }

})();
