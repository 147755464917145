(function(){
    'use strict';
    angular
    .module('app')
    .component('reportUnpaidApplications', {
      templateUrl: 'app/components/report-unpaid-applications/report-unpaid-applications.html',
      controller: ReportUnpaidApplicationsController
    });
  
    ReportUnpaidApplicationsController.$inject = ['user', '$rootScope', '$state', '_', 'globalService','$uibModal','url'];
  
    function ReportUnpaidApplicationsController($user, $rootScope, $state, _,$globalService, $uibModal, $url) {
      var vm = this;
      vm.loadingRequests = false;
      vm.currentUser = $user.getCurrentUser();
      vm.showCurrentApplication = false;
      vm.loadingData = false;
      var date = new Date();
      vm.date_from = new Date(date.getFullYear(), date.getMonth(), 1);
      vm.date_to = new Date();
      vm.params = {
        close: false,
        date_from: moment().startOf('month').format('MM/DD/YYYY'),
        date_to: moment().endOf('month').format('MM/DD/YYYY'),
      }
  
      vm.$onInit = function(){
        vm.loadingData = false;
        vm.params.date_from = moment(vm.params.date_from).format('YYYY-MM-DD');
        vm.params.date_to = moment(vm.params.date_to).format('YYYY-MM-DD');
        $rootScope.$emit('uploadBreadCrum', {current: 'Cuadre General', before: 'Home'});
        $globalService.getAllAgents.query(function(res){
          vm.agents = res;
        })
      }
  
  
      vm.consultApplications = function(){
        vm.loadingData = true;
        vm.params.date_from = moment(vm.date_from).format('YYYY-MM-DD');
        vm.params.date_to = moment(vm.date_to).format('YYYY-MM-DD');

        $globalService.reportUnpaidApplications(vm.params)
        .then(function(res){
          vm.loadingData = false;
          vm.reports = res;
          var params = vm.params;
          params.token = vm.currentUser.token;
          vm.urlReport = $globalService.reportPdfWithAction('reports','report_diff_payments', params);
          vm.urlReportExcel = $globalService.getUrlExcel('reports/report_diff_payments', params);
        });
      }

      vm.sendConciliationToEmail = function(){
        if(vm.params.user_id == undefined){
          swal("Error!", "Select an Agent First", "error");
          return;
        }
        
        vm.loadingData = true;
        vm.params.date_from = moment(vm.date_from).format('YYYY-MM-DD');
        vm.params.date_to = moment(vm.date_to).format('YYYY-MM-DD');
        vm.params.send_to_email = true;
        $globalService.reportUnpaidApplications(vm.params)
        .then(function(res){
          vm.loadingData = false;
          swal("Send!", res.message, "success");
        }, function(error){
          vm.loadingData = false;
          swal("Error!", error.data.message, "error");
        });
      }

  
      // column to sort
      vm.column = 'sno';
  
      // sort ordering (Ascending or Descending). Set true for desending
      vm.reverse = false;
  
      // called on header click
      vm.sortColumn = function (col) {
        vm.column = col;
        if (vm.reverse) {
          vm.reverse = false;
          vm.reverseclass = 'arrow-up';
        } else {
          vm.reverse = true;
          vm.reverseclass = 'arrow-down';
        }
      };
  
      // remove and change class
      vm.sortClass = function (col) {
        if (vm.column == col) {
          if (vm.reverse) {
            return 'fa-sort-amount-asc';
          } else {
            return 'fa-sort-amount-desc';
          }
        } else {
          return 'fa-sort';
        }
      }
  
    }
  
  
  })();
  