(function () {
  'use strict';
  angular
    .module('app')
    .component('paymentsApps', {
      templateUrl: 'app/components/modals/payments.html',
      controller: PaymentsAppController,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
      }
    });

  PaymentsAppController.$inject = ['$rootScope', 'globalService'];

  function PaymentsAppController($rootScope, $globalService) {
    var vm = this;
    vm.save = save;
    vm.deletePayment = deletePayment;
    vm.togglePaymentSubscription = togglePaymentSubscription;

    vm.$onInit = function () {
      vm.currentApplication = vm.resolve.currentApplication;
      console.log(vm.currentApplication)
      getPayment()
    }

    function getPayment() {
      $globalService.getPaymentCustomerApplication(vm.currentApplication.id)
      .then(function (res) {
        vm.payments = res;
      })
    }

    function togglePaymentSubscription() {
      $globalService.togglePaymentSubscription(vm.currentApplication)
      .then(function (res) {
        vm.currentApplication.payment_subscription = res.payment_subscription;
        var message = vm.currentApplication.payment_subscription ? 'Automatic payment subscription established' : 'automatic payment subscription disabled'
        swal('Info',message,'info')
      })
    }


    function save() {
      vm.send = true;
      vm.payment.customer_application_id = vm.currentApplication.id;
      vm.payment.date = moment(vm.date).format('YYYY-MM-DD');
      if(vm.payment.id){
        updatePayment()
      }else{
        savePayment();
      }
    }

    vm.edit = function(payment){
      vm.payment = payment;
    }

    function deletePayment(payment) {
      vm.send = true;
      swal({
        title: 'Are you sure',
        text: 'to delete this payment record?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.deletePaymentFromCustomerApp(payment)
              .then(function (res) {
                vm.send = false;
                getPayment();
                Swal('Exito', res.message, 'success');
              }, function (err) {
                Swal('Error', err.data.message, 'warning');
                vm.cancel({ $value: '' });
              })
          });
        },
        allowOutsideClick: false,
      }).then(function (data) { });
    }

    function savePayment(){
      swal({
        title: 'Are you sure',
        text: 'to add this payment?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.savePaymentCustomerApplication(vm.payment)
              .then(function (res) {
                vm.send = false;
                vm.payment = undefined;
                getPayment();
                Swal('Exito', res.message, 'success');
              }, function (err) {
                Swal('Error', err.data.message, 'warning');
                vm.cancel({ $value: '' });
              })
          });
        },
        allowOutsideClick: false,
      }).then(function (data) { });
    }

    function updatePayment(){
      swal({
        title: 'Are you sure',
        text: 'to update this payment?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.updatePaymentCustomerApplication(vm.payment)
              .then(function (res) {
                vm.send = false;
                vm.payment = undefined;
                getPayment();
                Swal('Exito', res.message, 'success');
              }, function (err) {
                Swal('Error', err.data.message, 'warning');
                vm.cancel({ $value: '' });
              })
          });
        },
        allowOutsideClick: false,
      }).then(function (data) { });
    }

    vm.cancel = function () {
      vm.dismiss({ $value: '' })
    }
  }

})();
