(function () {
	'use strict';
	angular
		.module('app')
		.component('documents', {
			templateUrl: 'app/components/documents/documents.html',
			controller: CustomerApplicationDocumentController
		});

	CustomerApplicationDocumentController.$inject = ['$uibModal', 'user', 'url', '$stateParams', '_', 'globalService', '$rootScope'];

	function CustomerApplicationDocumentController($uibModal, $user, $url, $stateParams, _, $globalService, $rootScope) {
		var vm = this;
		vm.crmId = null;
		vm.paramsStateId = false;
		if($stateParams.app_id){
			vm.paramsStateId = true;
			swal('Info','Write title before selecting the photo','info');
		}
		vm.loadingResumen = true;
		vm.dzOptions = {
			url: $url.getApiUrl('customer_applications') + '/' + $stateParams.app_id + '/documents?token='+$user.getCurrentUser().token,
			paramName: 'document',
			headers: { token: $user.getCurrentUser().token },
			maxFilesize: '10',
			addRemoveLinks: true,
		};

		vm.dzCallbacks = {
			'sending': function(file, xhr, formData) {
				formData.append('title', vm.title);
				console.log(file,xhr, formData)
			},
			'addedfile': function (file, formDAta) {
				// console.log(file, formDAta)
			},
			'success': function (file, response) {
				vm.loadingResumen = false;
				swal('Exito', response.message, 'success');
				vm.$onInit();
				vm.newFile = undefined;
				vm.title = undefined;
			},
		};

		vm.$onInit = function () {
			if($stateParams.app_id){
				vm.crmId = $stateParams.app_id;
			}
			if(vm.crmId != null){
				$rootScope.$emit('uploadBreadCrum', { current: 'Customer Application Docuemnts', before: 'Home' });
				$globalService.documents.query({ app_id: vm.crmId }).$promise
				.then(function (res) {
					vm.documents = res;
				})
			}
		}
		vm.updateDocument = function (params) {
			$globalService.documents.update({app_id: params.crmid, id: params.id}, params, function(res){
				swal('Exito', res.message, 'success');
				vm.$onInit();
			}, function(err){
				swal('Error', err.data.message, 'error');
			})
		}

		vm.deleteDocument = function (params) {
			swal({
        title: 'Are you Sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
						$globalService.documents.delete({app_id: params.crmid, id: params.id}, function(res){
							resolve(true);
						}, function () {
							vm.send = false;
							reject('Ha ocurrido un error');
						});
          });
        },
        allowOutsideClick: false,
      }).then(function (res) {
				if(res){
					swal('Exito','Document Delete succesfully','success');
					vm.$onInit();
				}
			});
			
		}

		vm.processDocument = function(params){
			$globalService.documents.process({app_id: params.crmid, id: params.id}, function(res){
				swal('Exito', res.message, 'success');
				vm.$onInit();
			}, function(err){
				swal('Error', err.data.message, 'error');
			})
		}

		$rootScope.$on('loadDatabases', function (evt, data) {
			vm.$onInit();
		})

		$rootScope.$on('setCurrentApplicant', function (event, data) {
      vm.crmId = data.current.id;
      console.log(vm.crmId)
			vm.$onInit();
    })

	}


})();
