(function(){
  'use strict';
  angular
  .module('app')
  .component('systemVersions', {
    templateUrl: 'app/components/system/system-version.html',
    controller: SystemVersionController
  });

  SystemVersionController.$inject = ['$rootScope','globalService']

  function SystemVersionController($rootScope, $globalService) {
    var vm = this;
    vm.updateMajor = updateMajor;
    vm.updateMinor = updateMinor;
    vm.updatePatch = updatePatch;
    vm.updateVersion = updateVersion;


    vm.$onInit = function(){
      vm.loadingData = true;
      $globalService.crmVersion.query().$promise
      .then(function(res){
        vm.loadingData = false;

        vm.currentVersion = res[0];
        console.log(vm.currentVersion)
      })
    }

    function updateMajor(){
      vm.currentVersion.major = parseInt(vm.currentVersion.major) + 1;
      vm.currentVersion.description = undefined;
      vm.currentVersion.minor = 0;
      vm.currentVersion.patch = 0;
    }
    function updateMinor(){
      vm.currentVersion.minor = vm.currentVersion.minor + 1;
      vm.currentVersion.patch = 0;
      vm.currentVersion.description = undefined;
    }
    function updatePatch(){
      vm.currentVersion.patch = vm.currentVersion.patch + 1;
      vm.currentVersion.description = undefined;
    }

    function updateVersion(){
      vm.loadingData = true;
      $globalService.crmVersion.save(vm.currentVersion).$promise
      .then(function(res){
        vm.loadingData = false;
        vm.systemVersion = res;
        swal('Success', 'Update sent successfully', 'success');
      })
    }




  }
})();
