(function(){
  'use strict';
  angular
    .module('app')
    .component('systemDashboard', {
      templateUrl: 'app/components/system/system-dashboard.html',
      controller: SystemDashboardController
    });

  SystemDashboardController.$inject = ['globalService']; // Agregado ;

  function SystemDashboardController($globalService) {
    var vm = this;

    vm.$onInit = function(){
      vm.loadingData = true;
      $globalService.system.query().$promise
        .then(function(res){
          vm.loadingData = false;
          vm.system = res;
          vm.logsArray = _.map(res.logs, function(value, key) {  // Reemplazado arrow function por compatibilidad
            return {
              name: key,
              size: value
            };
          });
        })
        .catch(function(err) {
          vm.loadingData = false;
          swal('Error', 'Error loading data', 'error');
        });
    };

    vm.deleteLog = function(log){
      vm.loadingData = true;
      swal({
        title: 'You are sure to delete this logs?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Accept',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.system.delete({id: log.name}).$promise
              .then(function(res){
                vm.loadingData = false;
                vm.system = res;
                vm.$onInit();
                swal('Success', 'Log deleted successfully', 'success');
                resolve(); // Resuelve la promesa correctamente
              })
              .catch(function (err) {
                vm.loadingData = false;
                swal('Error', err.data.message, 'warning'); // swal en minúscula
                reject(err); // Rechaza la promesa correctamente
              });
          });
        },
        allowOutsideClick: false,
      }).then(function (data) { });
    };
  }
})();
