(function(){
  'use strict';
  angular
  .module('app')
  .component('reportRecentDocuments', {
    templateUrl: 'app/components/reportRecentDocuments/recentDocuments.html',
    controller: ReportRecentDocumentsController
  });

  ReportRecentDocumentsController.$inject = ['user', '$rootScope', '$window', '_', 'globalService','$uibModal','url'];

  function ReportRecentDocumentsController($user, $rootScope, $window, _,$globalService, $uibModal, $url) {
    var vm = this;
    vm.loadingRequests = false;
    
    vm.showCurrentApplication = false;
    vm.loadingData = false;
    
    vm.$onInit = function(){
      vm.currentUser = $user.getCurrentUser();
      vm.date_from = moment().startOf('week').format('YYYY-MM-DD')
      vm.date_to = moment().endOf('week').format('YYYY-MM-DD')
      vm.params = {token: vm.currentUser.token};
      vm.params.date_from = moment(vm.date_from).startOf('week').format('YYYY-MM-DD');
      vm.params.date_to = moment(vm.date_to).endOf('week').format('YYYY-MM-DD');      
      $rootScope.$emit('uploadBreadCrum', {current: 'Report Recent Documents', before: 'Home'});
      vm.consultReport();
    }

    vm.consultReport = function(){
      vm.loadingData = true;
      vm.params.date_from = moment(vm.date_from).format('YYYY-MM-DD');
      vm.params.date_to = moment(vm.date_to).format('YYYY-MM-DD');
      $globalService.reportRecentDocuments(vm.params)
      .then(function(res){
        vm.loadingData = false;
        vm.reports = res;
        vm.print_excel_link = $globalService.reportExcel('recent_documents', vm.params);
        vm.total = _.reduce(vm.reports, function(memo, data){
          if(data.processed){
            memo.total_processed++;
          }else{
            memo.total_unprocessed++;
          }
          memo.total++;
          return memo;
        },{total_processed: 0, total_unprocessed: 0, total: 0});
      });
    }

    vm.column = 'sno';
    vm.reverse = false;
    vm.sortColumn = function (col) {
      vm.column = col;
      if (vm.reverse) {
        vm.reverse = false;
        vm.reverseclass = 'arrow-up';
      } else {
        vm.reverse = true;
        vm.reverseclass = 'arrow-down';
      }
    };

    vm.sortClass = function (col) {
      if (vm.column == col) {
        if (vm.reverse) {
          return 'fa-sort-amount-asc';
        } else {
          return 'fa-sort-amount-desc';
        }
      } else {
        return 'fa-sort';
      }
    }
  }


})();
