(function () {
  'use strict';
  angular
  .module('app')
  .component('processApplication', {
    templateUrl: 'app/components/modals/process_application.html',
    controller: ProcessApplicationController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ProcessApplicationController.$inject = ['$rootScope','globalService','user', 'url'];

  function ProcessApplicationController($rootScope, $globalService, $user, $url) {
    var vm = this;
    vm.save = save;
    vm.currentUser = $user.getCurrentUser();
    vm.documents = [];

    

    vm.$onInit = function() {
      vm.loading = true;
      vm.currentUser = $user.getCurrentUser();
      vm.currentApplication = vm.resolve.currentApplication;
      $globalService.users.query({use_npn: true}).$promise
      .then(function(res){
        vm.agents = res;
        vm.loading = false;
      })

      if(vm.currentUser.user_type_id == 2){
        vm.loading = true;
        $globalService.usersByCredentialsAgency()
        .then(function(res){
          vm.userSherpaAgencies = res;
          vm.loading = false;
        })
      }

      vm.dzOptions = {
        url : $url.getApiUrl('customer_applications/'+vm.currentApplication.id+'/documents/upload_elegibility_document'),
        paramName : 'document',
        maxFilesize : '1',
        acceptedFiles : 'application/pdf',
        addRemoveLinks : true,
        headers: {
          'Authorization': vm.currentUser.token
        }
      };
  
      vm.dzCallbacks = {
        'addedfile' : function(file){
          vm.newFile = file;
        },
        'success' : function(file, response){
          vm.loadingResumen = false;
          console.log(response,'response', file);
          vm.documents = response.pending_documents;
          swal('info', response.message, 'info');
        },
        'error' : function(file, response){
          swal('Error', response.message, 'error');
        }
      };
    }


    function save(){
      vm.send = true;
      vm.currentApplication.status = 2;
      if(vm.currentApplication.authorization_link){
        vm.currentApplication.status = 6;
      }
      if(vm.currentApplication.cms_note){
        vm.currentApplication.status = 7;
      }
      if(!vm.currentApplication.application_id){
        swal('Error','No ha indicado el FFMID', 'warning');
        return;
      }
      if(!vm.agentsSelected){
        swal('Error','No ha seleccionado un agente', 'warning');
        return;
      }
      $globalService.processApplication(vm.currentApplication)
      .then(function(res){
        vm.send = false;
        Swal('Exito',res.message,'success');
        vm.close({$value: res.data})
      }, function(err){
        Swal('Error',err.data.message,'warning');
        vm.cancel({$value: ''});
      })
    }

    vm.confirm = function(document){
      if(!vm.currentApplication.hasOwnProperty('required_documents_attributes')){
        vm.currentApplication.required_documents_attributes = [];
      }
      vm.currentApplication.required_documents_attributes.push(document);
      console.log(vm.currentApplication.required_documents_attributes)
    }

    vm.cancel = function(){
      vm.dismiss({$value: ''})
    }

    $rootScope.$on('setCurrentApplicant', function(event, data){
      vm.currentApplication = data.current;
      // //console.log(vm.currentApplication.dependents)
    })

  }

})();
